.custom-date-picker {
    width: 200px; /* Set the desired width */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
    color: #333;
    align-content: center;
    text-align: center;
  }



    .react-date-picker__wrapper {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            border: none;
    }
  
    .react-date-picker__calendar .react-calendar {
        border-width: thin;
        border-radius: 10px;
    }
  
  /* .custom-date-picker input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    color: #555;
  }
  
  .custom-date-picker button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .custom-date-picker button:hover {
    background-color: #0056b3; 
  } */